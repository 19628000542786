import Vue from 'vue'
import Router from 'vue-router'
import ViewUI from 'view-design'
import util from '../libs/util'
import i18n from '../local'
import store from '../store';

Vue.use(Router)
Vue.use(ViewUI)

const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

/**
 * 权限管理，动态路由
 */
export const asyncRouterMap = [
    {
        path: '/',
        name: 'layout',
        redirect: '/spaces/personSpaces',
        component: ()=>import('views/layout/layout'),
        children:[
            {
                path: '/personalCenter',
                name: 'personalCenter',
                meta: { title: 'personalCenter' },
                redirect: '/personalCenter/personalSettings',
                component: ()=>import('views/layout/personal'),
                children: [
                    {
                        path: '/personalCenter/personalSettings',
                        meta: { title: 'navigation.personalSettings', icon: 'icon-shezhi' },
                        name: 'personalSettings',
                        component: ()=>import('views/personal/settings/index')
                    }
                    // ,{
                    //     path: '/personalCenter/messages',
                    //     meta: { title: 'navigation.messages', icon: 'icon-gerenxiaoxi' },
                    //     name: 'messages',
                    //     component: ()=>import('views/personal/messages/index')
                    // }
                ]
            },{
                path: '/spaces',
                name: 'spaces',
                redirect: '/spaces/personSpaces',
                meta: { title: 'spaces' },
                component: ()=>import('views/layout/index'),
                children: [
                    { path: '/spaces/personSpaces', meta: { title: 'navigation.personSpaces', "icon": "icon-gerenkongjian" }, name: 'personSpaces', component: ()=>import('views/spaces/person') },
                    { path: '/spaces/companySpaces', meta: { title: 'navigation.companySpaces', "icon": "icon-qiyekongjian" }, name: 'companySpaces', component: ()=>import('views/spaces/company') },
                    { path: 'spaces/templateSpaces', meta: { title: 'navigation.templateSpaces', "icon": "icon-shangchengmoban" }, name: 'templateSpaces', component: ()=>import('views/spaces/template/index') }
                ]
            },
            {
                path: 'others',
                name: 'others',
                meta: { title: 'others' },
                component: ()=>import('views/layout/index'),
                children: [
                    { path: '/others/goods', meta: { title: 'navigation.goods', 'icon': 'icon-shangpin1' }, name: 'goods', component: ()=>import('views/others/goods/index') },
                    { path: '/others/templates', meta: { title: 'navigation.dataTemplates', 'icon': 'icon-shangchengmoban' }, name: 'dataTemplates', component: ()=>import('views/others/templates/index') }
                ]
            },
            {
                path: '/screens',
                name: 'screens',
                meta: { title: 'screens' },
                component: ()=>import('views/layout/index'),
                children: [
                    { path: '/screens/programs', meta: { title: 'navigation.programs', "icon": "icon-zhuanlan_jiemu" }, name: 'programs', component: ()=>import('views/screens/programs/index') },
                    { path: '/screens/mediaScreens', meta: { title: 'navigation.mediaScreens', "icon": "icon-pingmu2" }, name: 'mediaScreens', component: ()=>import('views/screens/mediaScreens/index') }
                ]
            },
            {
                path: '/tags',
                name: 'tags',
                meta: { title: 'tags' },
                component: ()=>import('views/layout/index'),
                children: [
                    { path: '/tags/smartTags', meta: { title: 'navigation.smartTags', "icon": "icon-pingmu2" }, name: 'smartTags', component: ()=>import('views/tags/smartTags/index') }
                ]
            },
            {
                path: '/organization',
                name: 'organization',
                meta: { title: 'organization' },
                redirect: '/organization/employee',
                component: ()=>import('views/layout/organization'),
                children: [
                    { path: '/organization/setting', meta: { title: 'navigation.setting', 'icon': 'icon-shezhi' }, name: 'setting', component: ()=>import('views/organization/setting/index') },
                    { path: '/organization/structure', meta: { title: 'navigation.structure', 'icon': 'icon-zuzhijiagou' }, name: 'structure', component: ()=>import('views/organization/structure/index') },
                    { path: '/organization/employee', meta: { title: 'navigation.employee', 'icon': 'icon-yuangong' }, name: 'employee', component: ()=>import('views/organization/employee/index') },
                    { path: '/organization/role', meta: { title: 'navigation.role', 'icon': 'icon-jiaose' }, name: 'role', component: ()=>import('views/organization/role/index') }
                ]
            }
        ]
    },
    {
        path: '*',
        component: ()=>import('views/404')
    }
]

export var constantRouterMap = [
    {
        path: '/template/design',
        name: 'templateDesign',
        meta: { title: 'navigation.templateDesign' },
        component: ()=>import('views/spaces/template/edit')
    },
    {
        path: '/program/edit',
        name: 'programEdit',
        meta: { title: 'navigation.programEdit' },
        component: ()=>import('views/screens/programs/edit')
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: 'layout.sysName' },
        component: ()=>import('views/login/index')
    },
    {
        path: '/invite_user',
        name: 'invite_user',
        meta: { title: 'layout.sysName' },
        component: ()=>import('views/login/invite')
    }
]

const createRouter = () => new Router({
    // mode: 'history',
    routes: constantRouterMap
})

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}

const router = createRouter()
const whiteList = ['/login', '/invite_user']

router.beforeEach((to,from,next)=>{
    ViewUI.LoadingBar.start();
    // 标题
    if (to.meta.title) {
        util.changeTitle(to.meta.title, i18n.locale);
    }
    if (whiteList.indexOf(to.path) !== -1) {

        return next()
    }
    // 验证是否登录
    let token=localStorage.getItem('token');
    if(token){
        if (store.getters.addRouters.length === 0) {
            let lang = localStorage.getItem('lang') || 'zh-Hans'
            store.commit('SET_LANG', lang)
            i18n.locale = lang
            store.dispatch('GenerateRoutes').then(() => {
                resetRouter()
                router.addRoutes(store.getters.addRouters)
                next({ ...to, replace: true })
            })
            .catch(() => {
                next({ name: 'login' })
            })
        } else {
            next()
        }
    }else{
        next('/login')
    }
});
router.afterEach(() => {
    ViewUI.LoadingBar.finish();
});

export default router