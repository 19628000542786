export const exCN={
    message: {
        success: '成功！',
        fail: '失败!',
        select: '请选择',
        errTip: '错误提示'
    },
    content: {
        
    },
    common: {
        cancel: '取消',
        confirm: '确定',
        total: '总数',
        input: '请输入',
        select: '请选择',
        searchLibrary: '搜索当前库',
        all: '全部',
        del: '删除',
        selectAll: '全选',
        selected: '已选中',
        content: '内容',
        post: '立即投放',
        edit: '编辑',
        createACopy: '创建副本',
        rename: '重命名',
        delTip: '删除提示',
        delCon: '删除资源后将无法重新找回，请谨慎操作！',
        confirmDel: '确定删除',
        sureDel: '确定删除吗？',
        success: '成功',
        tip: '提示',
        action: '操作',
        inputNumber: '请输入数字',
        add: '新增',
        limit: '不能超过16位字符',
        limit2: '不能少于8位字符',
        state: '状态',
        previousStep: '上一步',
        nextStep: '下一步',
        delAll: '全部删除',
        save: '保存',
        saveAs: '另存为',
        saveAndPost: '保存并投放',
        upTo32Characters: '最多32个字符',
        operationTime: '操作时间',
        operator: '操作用户',
        gotIt: '知道了',
        pixel: '像素',
        required: '不能为空',
        ok: '好的',
        reset: '重置',
        search: '查询',
        setting: '设置',
        copySuccess: '复制成功',
        copyFailed: '复制失败',
    },
    layout:{
        sysName: '小飞秀',
        logOut: '退出登录',
        switchAccount: '切换账号',
        currentUse: '当前使用',
        home: '首页',
        applet: '小程序',
        scan: '扫一扫',
        description: '投屏如此简单',
    },
    navigation: {
        spaces: '媒体素材',
        personSpaces: '个人空间',
        companySpaces: '企业空间',
        templateSpaces: '精选模板',
        templateDesign: '模板设计',
        screens: '数媒营销',
        programs: '节目',
        programEdit: '节目编辑',
        mediaScreens: '数媒屏',
        smartTags: '价签屏',
        personalCenter: '个人中心',
        personalSettings: '个人设置',
        organization: '企业中心',
        structure: '组织架构',
        employee: '员工管理',
        role: '角色管理',
        messages: '我的消息',
        setting: '企业设置',
        others: 'AI素材',
        goods: 'AI数据',
        dataTemplates: 'AI模板',
        tags: '智能价签',
    },
    pages: {
        login: {
            desp: '数字媒体一站式管理平台',
            passwordLogin: '密码登录',
            messageLogin: '短信登录',
            scanQR: '微信扫码',
            inputPhoneNumber: '请输入手机号',
            inputAccount: '请输入账号',
            inputAccount2: '请输入4-32位账号（英文字母、数字、_）',
            inputPassword: '请输入6-30位密码',
            inputGraphCode: '请输入图形验证码',
            SMSCodeLogin: '短信验证码登录',
            changePassword: '更改密码',
            forgetPassword: '忘记密码',
            login: '登录',
            inputCode: '请输入验证码',
            getCode: '获取验证码',
            passwordLogin2: '账号密码登录',
            readAndagree: '阅读并同意协议获取二维码',
            description: '开启数媒新世界',
            phoneNumberLogin: '手机号登录',
            phoneNumberRegistration: '手机号注册',
            agreed: '我已阅读并同意',
            userAgreement: '用户协议',
            and: '和',
            privacyPolicy: '隐私政策',
            inputNewPassword: '请输入新密码',
            rememberPassword: '想起密码',
            toLogin: '去登录',
            registerAnAccount: '注册账号',
            haveAnAccount: '已有账号?',
            verifyAndContinue: '验证并继续',
            enterpriseRegistration: '企业注册',
            enterpriseCreation: '创建新企业',
            inputEnterpriseName: '请输入企业(团队)名称',
            selectIndustryType: '请选择行业类型',
            inputInvitationCode: '请输入邀请码',
            contactCustomerService: '邀请码需要跟平台申请，请联系客服',
            confirmRegistration: '确认注册',
            loginEnterpriseAccount: '登录企业帐号',
            remenberSelection: '记住选择，仅主动退出登录可重新选择',
            notThisAccount: '不是该帐号？',
            loginAgain: '重新登录',
            personalAccountLogin: '管理我的个人资源',
            input4Digits: '请输入4位数字',
            inputDigitsAndLetters: '请输入6位数字、英文字母',
            readAndAgreeToLogin: '阅读并同意协议后方可登录',
            readAndAgreeToRegister: '阅读并同意协议后方可注册',
            joined: '您已加入企业',
            bindPhoneNumber: '绑定手机号',
            joined2: '你已加入',
            continue: '已为您自动创建个人账号，你可继续创建企业',
            searchCountry: '搜索您的国家和地区',
            upgrade: '升级成企业',
            upgradeTip: '您个人账号下的资源将全部转移到该企业名下',
            confirmUpgrade: '确认升级',
            currentAccount: '当前登录账号',
            invited: '邀请你加入',
            loginAndiJoin: '登录并加入企业',
            joinNow: '立即加入',
            enterCompany: '进入企业',
            backHome: '返回首页',
            loginPersonalAccount: '登录个人帐号',
            loginCompanyAccount: '登录企业帐号',
        },
        label: {
            unit: '个',
            labelMag: '标签管理',
            label: '标签',
            labels: '标签',
            editLabel: '编辑标签',
            add: '新建标签',
            inputName: '请输入标签名',
            limit: '标签名称不能超过',
            character: '字符',
            characters: '字符',
            addOrSearch: '新增或搜索标签',
            create: '创建新标签',
            search: '搜索标签',
            delTit: '删除标签',
            delCon: '是否删除标签？删除后含有该标签的内容不会被删除。',
            setLabel: '设置标签',
        },
        space: {
            copyTo: '复制到企业',
            createTemplate: '创建模板',
            uploadMaterials: '上传素材',
            noMaterials: '暂无素材',
            materialName: '素材名称',
            resolutionRatio: '分辨率',
            materialSize: '素材大小',
            postTip: '选中后，本次的投放将清空已选屏幕的播放计划，仅保留本次投放的节目',
            confirmSelect: '确认勾选',
            cancel: '暂不勾选',
            inputNameSearch: '输入素材名称搜索',
            select: '请选择素材',
            add: '添加素材',
            image: '图片',
            video: '视频',
            searchMaterials: '搜索素材',
            baseInfo: '基本信息',
            format: '格式',
            size: '大小',
            uploadTime: '上传时间',
            checkFailed: '以下内容检测不合法，无法上传',
            checking: '安全检测中',
            closeTip: '关闭后，将不再通知本次上传结果',
            wait: '再等等',
            confirmClose: '确认关闭',
            hide: '关闭，在后台上传',
            loading: '加载中',
            noMore: '没有更多数据',
            closeTip2: '关闭后资源需要重新上传，是否取消？',
            think: '我再想想',
            confirmCancel: '确定取消',
            uploading: '上传中',
            nameRequired: '名称不能为空',
            nameLength: '名称不能为超过32个字符',
            imageSizeLimit: '图片大小不能超过20M',
            imageSizeLimit2: '图片的宽或高不能超过30000px',
            imageSizeLimit3: '图片的总像素不能超过2',
            videoSizeLimit: '视频大小不能超过1G',
            audioSizeLimit: '音频大小不能超过1G',
            fileCountLimit: '单次上传，文件不能超过20个',
            unsupportedFormat: '不支持的格式',
            audio: '音频',
            gif: '动图',
            content: '内容',
            contents: '内容',
            unit: '个',
        },
        goods: {
            pc: '个',
            pcs: '个',
            data: '数据',
            add: '新建数据',
            edit: '编辑数据',
            fieldsMag: '数据字段管理',
            connectStore: '对接门店',
            panelsMag: '数据面板管理',
            inputToSearch: '输入数据名称、条码',
            quickPost: '快捷投放',
            noData: '暂无数据',
            delCon: '该删除操作为针对所有 门店的操作，如有其他门店仍在使用商品，请勿删除',
            delCon2: '是否确认删除？',
            connected: '已对接门店',
            more: '更多',
            addPanel: '新增面板',
            editPanel: '编辑面板',
            inputPanelKey: '请输入面板的唯一值，不可重复',
            panelName: '面板名称',
            inputPanelName: '请输入面板的名称，不可重复',
            panelsMagTip: '模板中的数据组件支持多面板，数据可选择不同的面板来展示不同的布局',
            panel: '面板',
            inputDigitsAndLetters: '请输入英文字母、数字',
            selectTmpl: '选择模板',
            selectScreen: '选择屏幕',
            showCode: '显示对接码',
            generateCode: '生成对接码',
            connectCode: '对接码',
            clearCode: '清空对接码',
            copyCode: '复制对接码',
            connectTip: '门店对接程序配置对应组织节点的“对接码”，就可以完成与该组织节点的对接',
            connectTime: '对接时间',
            applyToStore: '应用到门店',
            limit: '不能超过512个字符',
            customFields: '自定义字段',
            existingFields: '已有字段',
            selectFile: '选择文件',
            exclusiveImage: '专属图',
            exclusiveVideo: '专属视频',
            provideTip: '系统有提供数据对应的',
            useDirectly: '是否直接使用?',
            selecLocalFile: '选择本地文件',
            use: '直接使用',
            unsupportedFormat: '不支持的格式',
            addField: '新增字段',
            editField: '编辑字段',
            fieldKey: '字段key',
            fieldName: '字段名称',
            fieldType: '字段类型',
            matchTmpls: '已为您自动筛选匹配的模板',
            selectData: '选择数据',
            dataCenter: '数据中心',
            state: '数据状态',
            createData: '创建数据',
            selectedData: '已选数据',
            bindData: '绑定数据',
            dataPanel: '数据面板',
            confirmClear: '确认清空',
            clearPassCodeTip: '清空对接码后，对接将会失效，不再同步数据，但不会清除已对接数据',
            clearPassCodeTip2: '2、重新生成对接码，并在对接程序中重新配置新的对接码，将清空旧的已对接数据，并同步新的对接数据',
            confirrmRefresh: '确认更新',
            refreshTip: '更新对接码后，对接将会失效，不再同步数据，但不会清除已对接数据',
            refreshTip2: '在对接程序中重新配置新的对接码后，将清空旧的已对接数据，并同步新的对接数据',
            dynamicCode: '动态验证码',
            selectLabel: '请选择想设置的数据标签',
        },
        screen: {
            inputSearch: '搜索屏幕名称',
            inputSearch2: '搜索屏幕名称/屏幕ID',
            noScreen: '暂无屏幕',
            unit: '个',
            screen: '屏幕',
            screens: '屏幕',
            allScreens: '全部屏幕',
            group: '屏幕分组',
            addScreen: '添加屏幕',
            dir: '屏幕方向',
            category: '产品类型',
            volume: '音量',
            onOff: '开关机设置',
            transfer: '屏幕转移',
            transfer2: '转移',
            setNetWork: '配置网络',
            online: '在线',
            offline: '离线',
            record: '转移记录',
            screenRecord: '屏幕转移记录',
            transferSource: '转移（来自）',
            transferTarget: '转移（目标）',
            companyInternal: '企业内部',
            otherAccount: '其他账号',
            transferTip: '该操作会把所选的',
            transferTip2: '转移给其他的组织部门',
            transferTip3: '转移给其他账号',
            transferTip4: '无法撤销，请谨慎操作！',
            inputAccountID: '请输入账号ID',
            clearRecordsTip: '选中后，将清空转移记录',
            clearRecords: '清空转移记录',
            clearPlan: '清空播放计划',
            clearPlanTip: '选中后，本次的转移将清空已选屏幕的播放计划',
            and: '并',
            clearPlan2: '清空屏幕的播放计划',
            goToTransfer: '您将转移',
            to: '到',
            transferTip5: '该操作无法撤销！是否确认转移',
            upgrade: '升级',
            latestSysVer: '最新系统版本',
            currentVer: '当前版本',
            latestAppVer: '最新应用版本',
            sysUpdateCon: '系统更新内容',
            appUpdateCon: '应用更新内容',
            tipTit: '温馨提示',
            tipCon: '该升级包含系统升级，升级过程中无法使用屏幕',
            tipCon2: '升级会保留您的原有数据，请放心升级',
            waiting: '升级命令已下发，请等待设备升级',
            horizontal: '横屏',
            vertical: '竖屏',
            noGroup: '暂无分组',
            plan: '播放计划',
            addScreenTip: '添加屏幕方可正常使用',
            addTipCon: '新建分组成功，是否立即添加屏幕到分组',
            delGroupTip: '删除分组后不会影响屏幕现有的播放计划，是否确认删除？',
            addGroup: '新建分组',
            groupName: '分组名称',
            inputGroupName: '请输入分组名称',
            selectDir: '请选择屏幕方向',
            addGroupTip: '只能为相同方向的屏幕建立分组',
            selectableScreen: '可选屏幕',
            notJoined: '未加入分组',
            joined: '已加入其他分组',
            selectedScreen: '已选屏幕',
            removeTip: '确认将全部已选的屏幕移出该分组？',
            removeTip2: '确认将该屏幕移出该分组？',
            updatePlan: '已选屏幕的播放计划将更新为该分组的播放计划',
            bindingCode: '绑定码',
            inputBindingName: '请输入绑定码',
            name: '屏幕名称',
            numberOfScreens: '屏幕数量',
            inputScreenName: '请输入屏幕名称',
            editScreen: '修改屏幕信息',
            screenID: '屏幕ID',
            controlPower: '控制权',
            installationLocation: '安装位置',
            inputAddress: '请输入屏幕详细安装地址',
            administrator: '管理人员',
            inputAdministrator: '请输入管理人员',
            phoneNumber: '联系电话',
            inputPhoneNumber: '请输入联系电话',
            editDirTip: '修改屏幕方向将清空屏幕的播放列表，确认修改?',
            screenJoined: '您的屏幕已经加入',
            editDirTip2: '修改屏幕方向将会把屏幕移出分组。',
            editDirTip3: '修改屏幕方向将清空设备的播放列表。',
            editPlan: '修改计划',
            onOffSoon: '立即开关机',
            planSetting: '计划设置',
            onSoon: '立即开机',
            offSoon: '立即关机',
            startUpTime: '开机时间',
            shutDownTime: '关机时间',
            Monday: '周一',
            Tuesday: '周二',
            Wednesday: '周三',
            Thursday: '周四',
            Friday: '周五',
            Saturday: '周六',
            Sunday: '周天',
            newPost: '新建投放',
            noPlan: '暂无计划',
            longTermPlacement: '长期投放',
            dailyRepeat: '每天重复',
            playAllDay: '全天播放',
            editProgram: '编辑节目',
            removeProgram: '移除节目',
            crearProgram: '清空所有节目',
            sureDelAllPrograms: '确定删除所有节目吗？',
            editProgramTip: '编辑节目，所有已投放该节目的屏幕也将一并改变',
            continueEditProgram: '继续编辑节目',
            daySpan: '跨天',
            yearSpan: '跨年',
            illegal: '非法数据，跨天标签处理失败',
            illegal2: '非法数据，跨年标签处理失败',
            advertisingSettings: '投放设置',
            selectAdvCon: '选择投放内容（单选）',
            settingPlan: '设置播放计划',
            program: '节目',
            material: '素材',
            inputSearch3: '输入内容名称搜索',
            keepConnected: '请保持屏幕链接网络',
            password: '密码',
            netWorkInfoTip: '请确认配网信息是否正确，避免屏幕无法连接网络',
            confirmSet: '确认配网',
            reset: '重新设置',
            netWorkInfoTip2: '配网信息已下发，在线屏幕收到后会开始连接该网络，如果连接失败，屏幕将回到原来的网络或在屏幕断网的时候再次尝试连接该网络',
            cancelDelivery: '全部取消投放',
            noDelivery: '暂无投放',
            cancelDelivery2: '取消投放',
            morePlan: '更多计划',
            screenDeployed: '已投屏幕',
            cancelDelivery3: '是否取消投放',
            cancelDelivery4: '是否全部取消投放',
            repeatersMag: '中继管理',
            noTags: '暂无价签',
            replaceBind: '换绑数据',
            unbindData: '解绑数据',
            unbindTip: '确认解绑全部数据',
            startUnbind: '开始解绑数据',
            repeaterTip: '添加中继，仅支持通过小程序添加',
            unbind: '解绑',
            repeaterID: '中继ID',
            structureNode: '组织节点',
            repeaterIP: '中继IP',
            connectedCount: '已连接价签数量',
            registerTime: '注册时间',
            unbindTip2: '解绑后，中继下的墨水屏将自动删除',
            unbinded: '已解绑中继',
            bindedData: '已绑数据',
            noBindedData: '暂无绑定数据',
            confirmReplaceBind: '确认换绑',
            dataCount: ' 屏幕的模板商品数是',
            onlyChoose: '您只能选',
            transferRepeater: '中继转移',
            transferRepeaterTip: '该操作会把中继下面的墨水屏全部转移到对应的组织节点（门店）',
            large: '24/32/43/55寸大屏',
            small: '10寸小屏',
            planTip: '温馨提示：屏幕如有相同计划的节目，将做替换',
            selectDate: '选择日期',
            weeklySetting: '每周设置',
            selectTime: '选择时间',
            nextYear: '明年',
            selectScreen: '选择投放屏幕',
            confirmDeliver: '确认投放',
            coverDelivery: '覆盖投放',
            coverDeliveryTip: '投放将清空已选屏幕的播放计划，仅保留本次投放的节目',

        },
        template: {
            moreTmpls: '更多AI模板',
            inputSearch: '输入模板名称搜索',
            noTmpl: '暂无模板',
            search: '搜索',
            product: '产品',
            dir: '方向',
            type: '类型',
            useTmpl: '使用模板',
            template: '模板',
            name: '模板名称',
            searchTmpl: '搜索模板',
            otherMaterials: '小飞素材',
            in: '在',
            search2: '中搜索',
            size: '尺寸',
            batchReplaceData: '批量替换数据',
            binded: '该模板绑定了',
            maxLimit: '该模板最多只能选',
            qrcode: '二维码',
            deviceQrcde: '屏幕ID二维码',
            barcode: '条码',
            countdown: '倒计时',
            activityEnded: '活动已结束，敬请期待',
            cover: '覆盖',
            create: '创建',
            created: '已创建',
            reuse: '复用',
            upToMax: '已达最大值',
            resizeTip: '修改尺寸后，推荐的模板将会对应切换',
            resize: '调整尺寸',
            resizeTit: '调整模板尺寸',
            addText: '添加文字',
            configureFont: '请配置字体',
            text: '文字',
            inputText: '请输入文字',
            check: '查看',
            savedTo: '当前模版已保存到',
            saveSuccess: '保存成功',
            material: '素材',
            component: '组件',
            scale: '缩放',
            match: '不裁剪适配',
            cut: '剪切',
            copy: '复制',
            paste: '粘贴',
            down: '下移一层',
            up: '上移一层',
            bottom: '置于底层',
            top: '置于顶层',
            crop: '裁剪',
            replace: '替换',
            lock: '锁定',
            unlock: '解锁',
            sort: '排序',
            full: '完整价格',
            integer: '整数部分',
            decimal: '小数部分',
            split: '一键拆分整数和小数',
            join: '一键合并整数和小数',
            searchFont: '搜索字体',
            searchResult: '搜索结果',
            currentFont: '当前模版字体',
            alignLeft: '左对齐',
            alignCenter: '居中',
            alignRight: '右对齐',
            alignUp: '上对齐',
            alignVerCenter: '垂直居中',
            alignBottom: '下对齐',
            cn: '中文',
            en: '英文',
            cancelCombine: '取消组合',
            combine: '组合',
            combineTip: '包含数据组件无法组合',
            horDistribution: '水平分布',
            verDistribution: '垂直分布',
            dataAttributes: '数据属性',
            tmplSize: '模板尺寸',
            backgroundMusic: '背景音乐',
            timerMode: '计时模式',
            countdownTip: '选中后，倒计时将在每天的开始时间和结束时间之间进行不断重复倒计时',
            startTime: '开始时间',
            endTime: '结束时间',
            endText: '结束文案',
            countdownTip2: '计时结束将展示该文案',
            timerAccuracy: '计时精度',
            showUnit: '显示单位',
            date: '天',
            hour: '时',
            minute: '分',
            second: '秒',
            font: '字体',
            parameterSpacing: '参数间距',
            replaceTmplTip: '使用模板后当前页面内容将会被替换',
            beyondTip: '所选模板数据个数大于当前模板，会新增部分数据，是否继续操作？',
            beyondTip2: '所选模板数据个数小于当前模板，将解绑部分数据，是否继续操作？',
            videoLimit: '模板无法添加两个视频',
            videoLimit2: '每个模板仅允许添加一个视频，且视频只能在最底层',
            videoLimit3: '视频不支持复制操作',
            price: '价格',
            notCreated: '未创建',
            inputName: '请输入模板名',
            unknown: '未命名',
            invalidData: '无效数据',
        },
        program: {
            unit: '个',
            noProgram: '暂无节目',
            layout: '布局',
            module: '模块',
            contentList: '内容列表',
            playCount: '播放次数',
            times: '次',
            playDuration: '播放时长',
            seconds: '秒',
            backgroundSetting: '背景设置',
            size: '节目尺寸',
            backgroundMusic: '背景音乐',
            voicebroadcast: '语音播报',
            inputName: '请输入节目名',
            saveAs: '节目另存为',
            program: '节目',
            programs: '节目',
            add: '新建节目',
            search: '输入节目名称搜索',
            name: '节目名称',
            delTip: '节目删除后，屏幕将无法播放该节目，请谨慎操作',
            deliveredTo: '节目已投放到',
            played: '中播放，',
            addBkgMusic: '添加背景音乐',
            resize: '调整节目尺寸',
            resize2: '调整尺寸',
            voice: '声音',
            speed: '语速',
            audition: '试听',
            inputContent: '请输入自定义播报内容',
            example: '示例',
            tts: '确定合成语音',
            merge: '合并节目',
            size2: '节目大小',
            mergeTip: '合并节目不会删除原有的节目，将创建一个全新的节目，是否确认合并？',
        },
        notFound: {
            notFound: '抱歉！你访问的页面不存在',
            reload: '重新加载页面',
        },
        employee: {
            name: '姓名',
            inputName: '请输入用户名称',
            role: '角色',
            add: '新增用户',
            inviteUser: '邀请用户',
            remove: '移出企业',
            avatar: '用户头像',
            belongTo: '所属组织架构',
            inviter: '邀请者',
            removeTip: '您确定将员工',
            removeTip2: '移出您的企业吗？',
            inviteTit: '邀请链接已生成，让员工访问该链接即可加入企业',
            effectiveTime: '该链接将在24小时后失效',
            click: '点击复制',
            setUser: '设置用户',
            userName: '用户名称',
            noteName: '备注名称',
            userRole: '用户角色',
            generateLink: '生成链接',
            account: '账号',
            password: '密码',
            password2: '确认密码',
            inputPwd: '请输入密码',
            inputPwdAgain: '请再次输入密码',
            pwdErr: '密码不一致',
        },
        role: {
            name: '角色名称',
            note: '备注',
            creater: '创建用户',
            createTime: '创建时间',
            delTip: '您确定将删除角色',
            add: '新建角色',
            edit: '编辑角色',
        },
        setting: {
            companyInfo: '企业信息',
            upload: '上传',
            superAdmin: '超级管理员',
            companyID: '企业ID',
            companyIndustry: '企业行业',
            change: '更改',
            selectTit: '请选择企业当前所处的行业',
            selectTip: '系统将根据您所选的行业，为您推荐模版等行业数据',
            openEnterprise: '开通企业',
            industry: '行业',
            enterpriseName: '企业名',
            inputEnterpriseName: '请输入企业名称',
            transfer: '转让企业',
            inputPhoneNumberTip: '填入转让用户的手机号，该用户将成为企业的超级管理员',
            inputPhoneNumberTip2: '填入转让用户的账号，该用户将成为企业的超级管理员',
            transferTip: '转让企业后，您将离开企业，确定转让？',
            peddingEnterprise: '待处理的企业',
            unit: '位',
            employee: '员工',
            employees: '员工',
            join: '加入企业',
            reject: '拒绝企业',
            accept: '接受企业',
            inviteJoining: '邀请加入企业',
            selfCreated: '我创建的企业',
            cancelEnterprise: '注销企业',
            cancelTransfer: '取消转让',
            transfering: '转让中',
            joinedCompanies: '我加入的企业',
            leave: '离开企业',
            cancelTip: '注销企业后，企业的资源和设备将一并删除，请慎重操作！',
            reminderAgain: '再次提示',
            cancelSuccess: '注销成功',
            sureJoin: '确定加入？',
            sureReject: '确定拒绝？',
            acceptTip: '接受企业后，您将成为该企业的超级管理员，确定接受吗？',
            sureCancel: '确定取消转让吗？',
            sureLeave: '确定离开该企业吗？',
            leaveSuccess: '成功离开',
        },
        structure: {
            superiorGroup:  '上级分组',
            limit: '最多5位数字',
            newSubordinate: '新增下级',
            editGroup: '编辑分组',
            addGroup: '添加分组',
            subordinate: '下级',
        },
        personal: {
            inputOldPassword: '请输入旧密码',
            pwdErr: '新密码不一致',
            accountSetting: '账号设置',
            myCompany: '我的企业',
            phone: '手机',
            wechat: '微信',
            binded: '已绑定',
            unbinded: '未绑定',
            bind: '绑定',
            unbind: '解除绑定',
            setted: '已设置',
            notSetted: '未设置',
            myIndustry: '我的行业',
            unbindWechat: '解除微信绑定',
            unbindWechatTip: '解绑后将无法使用该微信号登录此账号，请谨慎操作！',
            think: '暂不解绑',
            confirmUnbind: '确认解绑',
            selectIndustry: '请选择你的行业',
            bindPhone: '绑定新手机',
            currentPhone: '现在手机',
            unbindedTip: '解绑后将无法使用该手机号登录此账号！',
            chandeSuccess: '更改成功',
            settingPassword: '设置密码',
            bindWechat: '绑定微信',
            scan: '扫码',
            follow: '关注',
            bindedSuccess: '绑定成功',
        }
    },
};